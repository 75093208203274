import React from 'react';
import { graphql } from 'gatsby';

import { ImageTextRow } from '../components/ImageTextRow/';
import PageLayout from '../components/PageLayout/';
import SEO from '../components/seo';
import { Hero } from '../components/Hero/';
import CompanyTestimonials from '../components/CompanyTestimonials/';
import Section from '../components/Section/';
import { Testimonials } from '../components/Testimonials/';
import Advantage, { AdvantageRow } from '../components/Advantage/';

import getEnvVars from '../environment';
import { Press } from '../components/Press/';
import {
  VideoSection,
  VideoHeader,
  VideoWrapper,
  VideoContainer,
  HiStarIcon,
  DropPointSection,
  DropPointHeader,
  FlexContainer,
  RegisterLink,
  AdvantageHeader,
  AdvantageContent,
  LinkContainer,
} from '../styles/pakete-verschicken';
import { SkinBroCaseStudySection } from '../components/SkinBroCaseStudy';

const { webAppUrl } = getEnvVars();

const B2BPage = ({
  data: {
    Hero1,
    Hero2,
    Hero3,
    Hero4,
    Hero5,
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    ImageKleiderstange,
    Business4,
    SkinBroCaseStudy,
  },
}) => {
  const images = [Hero1, Hero2, Hero3, Hero4, Hero5];

  return (
    <PageLayout>
      <SEO title="Unsere Angebote für Dein Unternehmen" />
      <Hero
        images={images}
        title={
          <>
            Für Online-Shops:
            <br />
            Spare Kosten & steigere den Umsatz.
          </>
        }
        subTitle={
          <>
            - bis zu 25 % weniger Service-Kosten
            <br />
            - Retouren vermeiden
            <br />- CO2 pro Paket einsparen
          </>
        }
        leftColumn={
          <FlexContainer tw={'justify-center mt-0'}>
            <div className="w-3/4 flex justify-center">
              <div className="lg:max-w-screen-md flex-1">
                <VideoContainer>
                  <iframe
                    // width="2032"
                    // height="400"
                    src="https://www.youtube.com/embed/6qDJWfbqo_8"
                    title="Video über DropFriends"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
                </VideoContainer>
              </div>
            </div>
          </FlexContainer>
        }
        fullscreen>
        <LinkContainer>
          <RegisterLink
            tw={'text-2xl'}
            href={`${webAppUrl}signup?companyType=sprint`}
            target="_blank"
            rel="noreferrer">
            Jetzt registrieren
          </RegisterLink>
        </LinkContainer>
      </Hero>
      <CompanyTestimonials />

      <SkinBroCaseStudySection image={SkinBroCaseStudy} />

      <AdvantageRow
        title="Mehr vom Kuchen"
        subTitle="Dein Geschäft startet durch"
        titleId="vorteile"
        className="justify-center">
        <Advantage icon={<HiStarIcon />} title="Reduziere Deine Kosten um bis zu 25%">
          <p>
            ...aufgrund ausbleibender Kundenanfragen zum Versand und zum Paketstandort. Vermeide
            zudem teure und klimakritische Retouren durch das innovative Empfangsmanagement Deiner
            eigenen Paketshop-Infrastruktur.
          </p>
        </Advantage>
        <Advantage icon={<HiStarIcon />} title="Paketkontrolle trotz Versand">
          <p>
            Gibt es Probleme mit der Bezahlung von Bestellungen? Deine verschickten Waren sind
            trotzdem sicher: Teile per Klick oder automatisiert mit, dass eine Paketübergabe vom
            DropPoint gesperrt werden soll.
          </p>
        </Advantage>
        <Advantage icon={<HiStarIcon />} title="Reduziere Deine Retouren">
          <p>
            Ermögliche die Übergabe von Paketen zu den Wunschzeiträumen Deiner Besteller. Unabhängig
            von ungünstigen Paketshop-Öffnungszeiten und haftungsrelevanten Sendungsablagen bei
            überrumpelten Nachbarn.
          </p>
        </Advantage>
        <Advantage icon={<HiStarIcon />} title="Paketzustandsdokumentation">
          <p>
            Der Zustand von platzierten Verkäufen wird für alle Parteien per Bild dokumentiert und
            in Echtzeit zur Verfügung gestellt. Haftungs-, Standort- und Zustellungsfragen sind
            transparent für alle geklärt.
          </p>
        </Advantage>
        <Advantage icon={<HiStarIcon />} title="Nutze alle Paketdienstleister">
          <p>
            Du versendest Deine Produkte mit Deinem frei gewählten Paketdienstleister und wir
            ermöglichen die Erstzustellung an die Wunschorte in der direkten Umgebung der Besteller.
          </p>
        </Advantage>
        <Advantage icon={<HiStarIcon />} title="Einzelhandelsförderer">
          <p>
            Platziere Click & Collect Verkäufe bei DropPoints, die als Übergabestandorte dienen.
            Same-Day-Unpacking, statt Next-Day-Delivery! Sei unabhängig von Öffnungszeiten &
            Anwesenheiten Deiner Kunden.
          </p>
        </Advantage>
        <Advantage icon={<HiStarIcon />} title="+ Werbefläche">
          <p>
            Tausende konsumfreudige DropFriends-Nutzer aus Deiner Nähe entdecken Dein Geschäft in
            der DropFriends-App. Deine digitale Laufkundschaft!
          </p>
        </Advantage>
      </AdvantageRow>

      <DropPointSection fullWidth>
        <DropPointHeader>Bereits 1500+ unabhängige DropPoints</DropPointHeader>
        <FlexContainer tw={'m-0'}>
          <RegisterLink
            href={`${webAppUrl}signup?companyType=sprint`}
            target="_blank"
            rel="noreferrer">
            Jetzt registrieren
          </RegisterLink>
        </FlexContainer>
      </DropPointSection>

      <Press />

      <Section title="Warum DropFriends anbieten?" fullWidth>
        <ImageTextRow leftImageFluid={Image1} position="left" fullWidth>
          <AdvantageHeader>Bis zu 25% Servicekosten sparen</AdvantageHeader>
          <AdvantageContent>
            Dein Online-Handel boomt und Deine Mailbox läuft mit Versandfragen Deiner Kunden heiß?
            Ungefragte Paketablagen bei Paketshops & Fremden forcieren Nicht-Abholungen und doppelte
            Wege. DropPoints sichern Erstzustellungen und vermeiden sowohl kostspielige Retouren und
            komplexe Kundenanfragen: Bis zu 25% Servicekosten werden eingespart und die
            Kundenzufriedenheit wird gestärkt.
          </AdvantageContent>
          <FlexContainer>
            <RegisterLink
              href={`${webAppUrl}signup?companyType=sprint`}
              target="_blank"
              rel="noreferrer">
              Jetzt registrieren
            </RegisterLink>
          </FlexContainer>
        </ImageTextRow>

        <VideoSection>
          <div className="text-center mb-12">
            <VideoHeader>Unsere Lösung für Online-Shops</VideoHeader>
          </div>
          <VideoWrapper>
            <FlexContainer tw={'justify-center mt-0'}>
              <div className="w-3/4 flex justify-center">
                <div className="lg:max-w-screen-md flex-1">
                  <VideoContainer>
                    <iframe
                      // width="2032"
                      // height="400"
                      src="https://www.youtube.com/embed/6qDJWfbqo_8"
                      title="DropFriends für den lokalen HAndel"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
                  </VideoContainer>
                </div>
              </div>
            </FlexContainer>
          </VideoWrapper>
        </VideoSection>

        <ImageTextRow rightImageFluid={Image2} position="right" fullWidth>
          <AdvantageHeader>Sicher ist sicher</AdvantageHeader>
          <AdvantageContent>
            Nach der Übergabe Deiner Verkäufe an Paketdienstleister kann viel passieren:
            Kundenzahlungen platzen, Mahnprozesse und Warenabschreibungen. Mit DropFriends behälst
            Du trotzdem die Kontrolle über die Versandstücke. Sperre die Paketübergabe durch den
            DropPoint, bis alles geklärt ist - DSGVO konform. Zudem erfolgen Abholungen nur nach
            erfolgreicher Identifizierung: Per Fotoabgleich oder Prüfung des Ausweises.
          </AdvantageContent>
          <FlexContainer>
            <RegisterLink
              href={`${webAppUrl}signup?companyType=sprint`}
              target="_blank"
              rel="noreferrer">
              Jetzt registrieren
            </RegisterLink>
          </FlexContainer>
        </ImageTextRow>

        <ImageTextRow leftImageFluid={Image3} position="left" fullWidth>
          <AdvantageHeader>EinzelhändlerInnen aufgepasst</AdvantageHeader>
          <AdvantageContent>
            Das digitale Aussuchen und bezahlen von Waren in Deinem Click & Collect-Shop ist nun
            losgelöst von der eigentlichen Produktübergabe. Übergaben werden unabhängig von Deinen
            Öffnungszeiten ermöglicht. Positioniere einen starken, lokalen und praktischen Mehrwert
            für Deine Kunden. Ein Magnet zur Kundenbindung der sich rumspricht. Same Day Unpacking,
            statt next Day Delivery!
          </AdvantageContent>
          <FlexContainer>
            <RegisterLink
              href={`${webAppUrl}signup?companyType=sprint`}
              target="_blank"
              rel="noreferrer">
              Jetzt registrieren
            </RegisterLink>
          </FlexContainer>
        </ImageTextRow>

        <VideoSection>
          <div className="text-center mb-12">
            <VideoHeader>Unsere Lösung für den lokalen Handel</VideoHeader>
          </div>
          <VideoWrapper>
            <FlexContainer tw={'justify-center mt-0'}>
              <div className="w-3/4 flex justify-center">
                <div className="lg:max-w-screen-md flex-1">
                  <VideoContainer>
                    <iframe
                      // width="2032"
                      // height="400"
                      src="https://www.youtube.com/embed/nMHmVIzOgt0"
                      title="DropFriends für den lokalen HAndel"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
                  </VideoContainer>
                </div>
              </div>
            </FlexContainer>
          </VideoWrapper>
        </VideoSection>

        <ImageTextRow rightImageFluid={Image4} position="right" fullWidth>
          <AdvantageHeader>Stärke Deine Marke und Dein Geschäft</AdvantageHeader>
          <AdvantageContent>
            Warum riskierst Du die Sicherheit Deiner umsatzstärksten Verkäufe und das Image Deiner
            Marke durch fremdes Personal? Paketboten als Drittpartei entscheiden aktuell frei über
            Paket-Ablageorte bei Nicht-Antreffen der Sendungsempfänger. Mit DropFriends rücken die
            Bedürfnisse Deiner Kunden in den Vordergrund, ohne dass Händler die Unabhängigkeit für
            ihre Marke und Sicherheit aufgeben. Du bist der Profi wenn es ums Geschäft, Waren und
            Deine Kunden geht. Du weißt es zu führen und es erfolgreich zu machen. Jetzt auch mit
            eigenen Übergabepunkten. Selbstbestimmt, flexibel und wirklich kundennah.
          </AdvantageContent>
          <FlexContainer>
            <RegisterLink
              href={`${webAppUrl}signup?companyType=sprint`}
              target="_blank"
              rel="noreferrer">
              Jetzt registrieren
            </RegisterLink>
          </FlexContainer>
        </ImageTextRow>

        <ImageTextRow leftImageFluid={Image5} position="left" fullWidth>
          <AdvantageHeader>Paketempfang. In gut!</AdvantageHeader>
          <AdvantageContent>
            DropFriends kümmert sich für Dich und Deine Kunden um das angenehmste Empfangsmanagement
            für Pakete, dass es je gab. Biete Deine Waren über Deinen Shop an und ermögliche die
            Übergabe zu Wunschzeiträumen Deiner Besteller - in der Nachbarschaft! Egal mit welchem
            Paketdienstleister.
          </AdvantageContent>
        </ImageTextRow>
        <ImageTextRow rightImageFluid={Image6} position="right" fullWidth>
          <AdvantageHeader>Der Umwelt zuliebe</AdvantageHeader>
          <AdvantageContent>
            Mehrere Zustellversuche durch Paketdienstleister werden durch die Nutzung eines
            DropPoints reduziert. Das verringert den CO2-Ausstoß pro Paket auf bis zu 277g. Die
            monatliche Gesamteinsparung errechnen wir Dir automatisch im DropFriends-Dashboard,
            damit Du Deine Kunden mit einer echten Kennzahl über Dein Engagement zum Umweltschutz
            informieren kannst.
          </AdvantageContent>
          <FlexContainer>
            <RegisterLink
              href={`${webAppUrl}signup?companyType=sprint`}
              target="_blank"
              rel="noreferrer">
              Jetzt registrieren
            </RegisterLink>
          </FlexContainer>
        </ImageTextRow>

        <ImageTextRow
          rightImageFluid={Business4}
          // rightImageMobileLast
          leftImageFluid={ImageKleiderstange}
          fullWidth
        />
      </Section>

      <Testimonials
        className="bg-gray-200"
        testimonials={[
          {
            testimonial: (
              <>
                Funktioniert!
                <br />
                In Köln gibts enorm viele Annahmestellen und ich habe meinen gefunden. Probiert‘s
                aus!
              </>
            ),
            name: 'Katrin',
            source: 'Play Store',
          },
          {
            testimonial:
              'Ich kann die App wärmstens empfehlen. Bisher hat alles super geklappt und es kommen dauernd neue Orte hinzu, wo ich was hin liefern kann. Nach dem ganzen Stress, den ich bisher in meinem Leben mit Packstationen hatte, bin ich echt erleichtert, dass sich jemand um eine bessere Lösung bemüht hat.',
            name: 'Dennis ',
            source: 'Play Store',
          },
          {
            testimonial: (
              <>
                Super!
                <br />
                Dank der App fühle ich mich bzw. meine Pakete gut aufgehoben. Das hier braucht jeder
                und mein Gott, wie einfach alles war. Einfach genial!!!
              </>
            ),
            name: 'Engin ',
            source: 'Play Store',
          },
          {
            testimonial: (
              <>
                Super App!
                <br />
                Läuft stabil und tut was sie soll.
              </>
            ),
            name: 'Chris ',
            source: 'Play Store',
          },
          {
            testimonial:
              'Finde, das ist eine sehr clevere App, viel Potenzial ist da! Ich habe schon einen DropPoint aufgemacht. Finde die Idee super!',

            name: 'Phillip ',
            source: 'Play Store',
          },
          {
            testimonial: (
              <>
                Super App!
                <br />
                Unbedingt ausprobieren.
              </>
            ),
            name: 'Oleg ',
            source: 'Play Store',
          },
        ]}
        children={
          <FlexContainer tw={'justify-center'}>
            <RegisterLink
              tw={'text-2xl'}
              href={`${webAppUrl}signup?companyType=business`}
              target="_blank"
              rel="noreferrer">
              Jetzt registrieren
            </RegisterLink>
          </FlexContainer>
        }
      />

      <AdvantageRow
        title="DropFriends Online-Software und Public API"
        titleId="app"
        className="justify-center">
        <Advantage icon={<HiStarIcon />} title="Schnelle Registrierung"></Advantage>
        <Advantage icon={<HiStarIcon />} title="Für alle Paketdienstleister"></Advantage>
        <Advantage icon={<HiStarIcon />} title="Made in Germany | Köln"></Advantage>
        <Advantage icon={<HiStarIcon />} title="Zentrale Steuerung über Deinen Browser"></Advantage>
        <Advantage
          icon={<HiStarIcon />}
          title="Simples Handling per Benutzernamen und Passwort"></Advantage>
      </AdvantageRow>
    </PageLayout>
  );
};

export const query = graphql`
  {
    Hero1: file(relativePath: { eq: "sprint-lp/001_hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero2: file(relativePath: { eq: "sprint-lp/002_hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero3: file(relativePath: { eq: "sprint-lp/003_hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero4: file(relativePath: { eq: "sprint-lp/004_hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero5: file(relativePath: { eq: "sprint-lp/005_hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Image1: file(relativePath: { eq: "sprint-lp/001_kosten_sparen.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Image2: file(relativePath: { eq: "sprint-lp/002_sicher.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Image3: file(relativePath: { eq: "sprint-lp/003_einzelhandel.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Image4: file(relativePath: { eq: "sprint-lp/004_marke_staerken.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Image5: file(relativePath: { eq: "sprint-lp/005_guter_paketempfang.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Image6: file(relativePath: { eq: "sprint-lp/006_umwelt.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    ImageKleiderstange: file(
      relativePath: { eq: "paketshop-werden/lp_business_kleiderstange.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Business4: file(relativePath: { eq: "b2b-business-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    SkinBroCaseStudy: file(relativePath: { eq: "cs_woman_green_central.png" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default B2BPage;
