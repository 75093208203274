import tw, { styled } from 'twin.macro';
import { HiStar } from 'react-icons/hi';
import Section from '../components/Section/';

export const Nav = styled.nav(({ isScrolling }) => [
  tw`fixed w-full z-40 top-0 text-white`,
  isScrolling ? tw`bg-white shadow-lg` : '',
]);

export const LogoWrapper = tw.div`lg:w-screen mx-auto flex flex-wrap items-center justify-between mt-0 py-0`;

export const LogoContainer = tw.div`flex flex-1 items-center py-4 pl-4 md:pl-8`;

export const VideoSection = tw.div`w-full bg-green-500 py-8 md:py-16 px-8`;

export const VideoHeader = tw.h2`font-display text-2xl lg:text-4xl tracking-tight font-extrabold text-gray-200 sm:text-4xl`;
export const VideoWrapper = tw.div`container mx-auto flex flex-wrap flex-col md:flex-row justify-center`;

export const VideoContainer = tw.div`overflow-hidden aspect-w-16 aspect-h-9 relative`;

export const HiStarIcon = tw(HiStar)`mx-auto w-16 h-16 mb-4 text-yellow-400 `;

export const DropPointSection = tw(Section)`text-center py-16 md:py-24 px-8 md:px-16 bg-yellow-400`;

export const DropPointHeader = tw.h2`font-display w-full mb-12 text-4xl md:text-5xl lg:text-6xl font-bold leading-tight text-center`;

export const FlexContainer = tw.div`w-full flex justify-center mt-8`;

export const RegisterLink = tw.a`font-display font-bold text-xl tracking-wide block py-3 px-12 rounded-lg text-white bg-green-500 hover:bg-green-600`;

export const AdvantageHeader = tw.h3`font-display text-3xl font-bold mb-4 tracking-wide`;
export const AdvantageContent = tw.p`text-gray-700 text-lg md:text-xl`;

export const LinkContainer = tw.div`flex items-center mt-4`;
