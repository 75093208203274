import React, { useContext } from 'react';
import { Button, Link } from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';

import SpinnerContext from '../../contexts/SpinnerContext';
import { useCustomToast } from '../../hooks/useCustomToast';
import Input from '../Input';
import { SKINBRO_CASESTUDY_SUBMIT } from './query';
import { ImageTextRow } from '../ImageTextRow';
import Section from '../Section';

const SkinBroCaseStudySchema = Yup.object().shape({
  firstName: Yup.string().required('Ergänzen Sie Ihre Angaben'),
  lastName: Yup.string().required('Ergänzen Sie Ihre Angaben'),
  email: Yup.string().email('Korrigieren Sie Ihre Angabe').required('Ergänzen Sie Ihre Angaben'),
});

export const SkinBroCaseStudySection = ({ image }) => {
  const [skinBroCaseStudySubmit] = useMutation(SKINBRO_CASESTUDY_SUBMIT);
  const toast = useCustomToast();
  const { toggleSpinner } = useContext(SpinnerContext);

  return (
    <Section fullWidth>
      <ImageTextRow leftImageFluid={image} position="left" fullWidth containerLessYPadding>
        <div className="flex flex-col">
          <div className="text-center lg:text-left">
            <h2 className="font-display w-full mb-4 md:mb-8 text-2xl lg:text-3xl font-bold leading-tight text-gray-800">
              Die exklusive SKINBRO x DropFriends Case Study!
            </h2>
            <p className="mb-4">
              SKINBRO hat das innovative Empfangsmanagement von DropFriends über 4 Monate getestet:
            </p>
            <p className="italic mb-4">
              "Unsere Kunden waren von dem Feature begeistert. Besonders gefreut hat uns, dass die
              Kundenzufriedenheit so auch nochmal gestiegen ist." sagt Valentin Müller,
              Geschäftsführer von SKINBRO.
            </p>
            <p className="mb-8">
              Entdecken Sie die Optimierungs-Potenziale auch für Ihren Online-Shop.
            </p>
          </div>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              url: '',
            }}
            validationSchema={SkinBroCaseStudySchema}
            onSubmit={async ({ email, firstName, lastName, url }) => {
              toggleSpinner(true);
              try {
                await skinBroCaseStudySubmit({
                  variables: {
                    email,
                    firstName,
                    lastName,
                    url,
                  },
                });
                toast({
                  title: 'Vielen Dank! 🎉',
                  description: 'Wir haben Ihnen die Case Study per E-Mail zugeschickt.',
                  status: 'success',
                });
              } catch (error) {
                console.log(error);
                toast({
                  description: 'Ein Fehler ist aufgetreten!',
                  status: 'error',
                });
              }
              toggleSpinner(false);
            }}>
            <Form>
              <div className="grid grid-cols-2 gap-4">
                <Input name="firstName" isRequired label="Vorname*" />
                <Input name="lastName" isRequired label="Nachname*" />
              </div>
              <Input name="email" isRequired type="email" label="E-Mail Adresse*" />
              <Input name="url" label="Ihre Online-Shop Adresse" />
              <p className="text-xs mb-6">*Pflichtfeld</p>
              <p className="text-sm text-gray-600 mb-4">
                Ich stimme der Verarbeitung und Speicherung meiner Daten gemäß der{' '}
                <Link href="/Datenschutzerklärung_16.03.2022.pdf" target="_blank">
                  Datenschutzbestimmungen
                </Link>{' '}
                zu.
              </p>

              <Button colorScheme="brand" variant="solid" width="full" type="submit">
                Jetzt Case Study anfordern
              </Button>
            </Form>
          </Formik>
        </div>
      </ImageTextRow>
    </Section>
  );
};
