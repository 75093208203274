import gql from 'graphql-tag';

export const SKINBRO_CASESTUDY_SUBMIT = gql`
  mutation SKINBRO_CASESTUDY_SUBMIT(
    $firstName: String!
    $lastName: String!
    $email: String!
    $url: String
  ) {
    skinBroCaseStudySubmit(firstName: $firstName, lastName: $lastName, email: $email, url: $url) {
      id
    }
  }
`;
